import React, {MutableRefObject, useRef, useState} from 'react';
import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons'
import './App.scss';
import dottedTriangle from './assets/dotted-triangle.svg';
import stripedL from './assets/striped-l.svg';
import rings from './assets/rings.svg';
import halfSplotchBeige from './assets/half-splotch-beige.svg'
import halfSplotchGreen from './assets/half-splotch-green.svg'
import logo from './assets/ne-logo.svg'
import VizSensor from 'react-visibility-sensor';
import {useTrail, animated} from "react-spring";

const SLIDE_MIN_TOP_VALUE = 0;
const LIST_TRAIL_CONFIG = { mass: 2, tension: 1500, friction: 100 };

function App() {
    const parallaxContainer: MutableRefObject<Parallax | null> = useRef(null);

  return <>
      <PageHeader />
      <main className={'app-container'}>
          <Parallax pages={3} ref={parallaxContainer}>
              <BackgroundQuote offset={0}>Talk&nbsp;is&nbsp;cheap,<br/>show&nbsp;me&nbsp;code</BackgroundQuote>
              <SecondFiguresLayer offset={0} />
              <ParallaxLayer offset={0} speed={0.8}>
                  <div className={"parallax-layer"}>
                      <FirstScreen />
                  </div>
              </ParallaxLayer>
              <ParallaxLayer offset={1} speed={0.8}>
                  <div className={"parallax-layer"}>
                      <SecondScreen />
                  </div>
              </ParallaxLayer>
              <FirstFiguresLayer offset={1} />
              <BackgroundQuote offset={1}>Simplicity = <br/>sophistication</BackgroundQuote>
              <ThirdFiguresLayer offset={2} />
              <ParallaxLayer offset={2} speed={0.8}>
                  <div className={"parallax-layer"}>
                      <ThirdScreen />
                  </div>
              </ParallaxLayer>
          </Parallax>
      </main>
  </>;
}



function FirstScreen() {
    const [isFirstLayerVisible, setFirstLayerVisible] = useState(false);

    let highlightClasses = "heading-display__highlight";
    if(isFirstLayerVisible) highlightClasses += ' heading-display__highlight--active';
    return <>
        <div className="container">
            <VizSensor onChange={toggleVisible(setFirstLayerVisible)} minTopValue={SLIDE_MIN_TOP_VALUE}>
                <>
                    <div className={"layer-heading"}>
                        <h1 className="heading-display">
                            <span className={highlightClasses}>Hi,</span> my name is <br/>Nico Eshuis
                        </h1>
                        <span className="dotted-trail" />
                    </div>
                    <div className={"layer-content"}>
                        <p>
                            For 8+ years I have been doing software engineering. I have developed myself to be a strong <strong>developer</strong> and my specialty is Javascript/Typescript development, with good front-end and back-end experience.
                        </p>
                    </div>
                </>
            </VizSensor>
        </div>
        </>
}

function SecondScreen() {
    const [isSecondLayerVisible, setSecondLayerVisible] = useState(false);

    let highlightClasses = "heading-display__highlight";
    if(isSecondLayerVisible) highlightClasses += ' heading-display__highlight--active';

    const listItems = ["React", "Node.js", "Typescript", "HTML / CSS", "AWS (♥ Serverless)", "Docker", "Cypress"];

    const trail = useTrail(listItems.length, createListTrailConfig(LIST_TRAIL_CONFIG, isSecondLayerVisible));

    return <>
        <div className="container">
            <VizSensor onChange={toggleVisible(setSecondLayerVisible)} minTopValue={SLIDE_MIN_TOP_VALUE}>
                <>
                    <div className={"layer-heading"}>
                        <h2 className="heading-display">My latest <span className={highlightClasses}>tech</span> loves</h2>
                    </div>
                    <div className={"layer-content"}>
                        {renderListTrail(trail, listItems)}
                        <p>
                            I'd be happy to explain my full range of experiences.
                        </p>
                    </div>
                </>
            </VizSensor>
        </div>
    </>
}

function ThirdScreen() {
    const [isThirdLayerVisible, setThirdLayerVisible] = useState(false);

    let highlightClasses = "heading-display__highlight";
    if(isThirdLayerVisible) highlightClasses += ' heading-display__highlight--active';

    const listItems = ["RTL (Videoland)", "Parkgenoot", "Nike", "KPN", "Rabobank", "Listi", "TLN", "Up Learning"];

    const trail = useTrail(listItems.length, createListTrailConfig(LIST_TRAIL_CONFIG, isThirdLayerVisible));

    return <>
        <div className="container">
            <VizSensor onChange={toggleVisible(setThirdLayerVisible)} minTopValue={450}>
                <>
                    <div className={"layer-heading"}>
                        <h2 className="heading-display">I enjoy(ed) <span className={highlightClasses}>working</span> for</h2>
                    </div>
                    <div className={"layer-content"}>
                        {renderListTrail(trail, listItems)}
                    </div>
                </>
            </VizSensor>
        </div>
    </>
}

interface FirstFiguresLayerProps {
    offset: number
}

const FirstFiguresLayer:React.FC<FirstFiguresLayerProps> = ({offset}) => {
    return <>
        <div className={'container'}>
            <ParallaxLayer offset={offset} speed={0.4}>
                <img className={'parallax-figure'} src={halfSplotchBeige} alt={"Half splotch beige"}  style={{transform: 'rotate(-35deg) translateX(30px) translateY(-40px)'}}/>
            </ParallaxLayer>
            <ParallaxLayer offset={offset} speed={0.7}>
                <img className={'parallax-figure'} src={halfSplotchGreen} alt={"Half splotch green"}  style={{transform: 'rotate(95deg)  translateX(-70px) translateY(-30px)'}}/>
            </ParallaxLayer>
            <ParallaxLayer offset={offset} speed={1}>
                <img className={'parallax-figure'} src={dottedTriangle} alt={"Dotted triangle"}  style={{transform: 'rotate(45deg) translateY(-100px) translateX(60px)'}}/>
            </ParallaxLayer>
        </div>
    </>
};

const SecondFiguresLayer:React.FC<FirstFiguresLayerProps> = ({offset}) => {
    return <>
        <div className={'container'}>
        <ParallaxLayer offset={offset} speed={0.4}>
            <img className={'parallax-figure'} src={halfSplotchBeige} alt={"Half splotch beige"} style={{transform: 'rotate(-35deg) translateX(80px) translateY(-80px)'}}/>
        </ParallaxLayer>
        <ParallaxLayer offset={offset} speed={0.7}>
            <img className={'parallax-figure'} src={halfSplotchGreen} alt={"Half splotch green"} style={{transform: 'rotate(95deg)  translateX(-90px) translateY(-80px)'}}/>
        </ParallaxLayer>
        <ParallaxLayer offset={offset} speed={1}>
            <img className={'parallax-figure'} src={stripedL} alt={"Striped L"}  style={{transform: 'rotate(45deg) translateY(-150px) translateX(10px)'}}/>
        </ParallaxLayer>
        </div>
    </>
};

const ThirdFiguresLayer:React.FC<FirstFiguresLayerProps> = ({offset}) => {
    return <>
        <div className={'container'}>
            <ParallaxLayer offset={offset} speed={0.4}>
                <img className={'parallax-figure'} src={halfSplotchBeige} alt={"Half splotch beige"} style={{transform: 'rotate(45deg)  translateX(-80px) translateY(-80px)'}}/>
            </ParallaxLayer>
            <ParallaxLayer offset={offset} speed={0.7}>
                <img className={'parallax-figure'} src={halfSplotchGreen} alt={"Half splotch green"}  style={{transform: 'rotate(15deg) translateX(-80px) translateY(-80px)'}}/>
            </ParallaxLayer>
            <ParallaxLayer offset={offset} speed={1}>
                <img className={'parallax-figure'} src={rings} alt={"Rings"}  style={{transform: 'rotate(45deg) translateX(-20px) translateY(-100px)'}}/>
            </ParallaxLayer>
        </div>
    </>
};

function renderListTrail(trail: any[], listItems: string[]) {
    return <ul className={"list-trail"}>
        {trail.map(({ x, height, ...rest }: any, index: number) => (
            <animated.li
                key={listItems[index]}
                className="list-trail-item"
                style={{ ...rest, transform: x.interpolate((x: any) => `translate3d(0,${x}px,0)`) }}>
                {listItems[index]}
            </animated.li>
        ))}
    </ul>
}

function createListTrailConfig(config: any, toggleProp: boolean) {
    return {
        config,
        opacity: toggleProp ? 1 : 0,
        x: toggleProp ? 0 : 20,
        from: { opacity: 0, x: 0 },
    }
}

interface BackgroundQuoteProps {
    offset: number
}

const BackgroundQuote:React.FC<BackgroundQuoteProps> = ({offset, children}) => {
    return <ParallaxLayer offset={offset} speed={0.3} factor={0.5}>
        <div className="background-quote-container">
            <div className="container">
                <span className="background-quote">{children}</span>
            </div>
        </div>
    </ParallaxLayer>
};

function toggleVisible(fn: (isVisible: boolean) => void) {
    return (isVisible: boolean) => fn(isVisible);
}

function PageHeader() {
    return <header className={"page-header"}>
        <div className={"container util-flex util-space-between util-align-center"}>
            <img src={logo} alt={"NE"}/>
            <a href="https://www.linkedin.com/in/nicoeshuis/" rel="nofollow">LinkedIn</a>
        </div>
    </header>
}

export default App;
